import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';

import messages from './messages';

export const SeeAnnexPDFButton = () => (
  <Button size="md" emphasis="medium">
    <FormattedMessage {...messages.seeAnnex} />
  </Button>
);
